import {
  serialActions,
  asyncActions,
  actionsGeneratorV2,
  actionsGenerator,
} from '@docavenue/core';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import React from 'react';
import {
  MfaDTO,
  User,
} from '@maiia/model/generated/model/api-patient/api-patient';
import dayjs from 'dayjs';
import { get } from 'lodash';

import { safeRedirectURL, sleep } from '@/src/utils';

import {
  authActions,
  linkSSOAction,
  pharmacyUsersActions,
  usersActions,
} from '@/src/actions';
import { isMutual } from '@/components/utils/teleconsultationRelay';
import { APP_ROUTES, SSO_DEFAULT_REDIRECT_TARGET } from '@/src/constants';
import { PatientUser } from '@/components/molecules/WithAuth/types';
import { screebEventTrack } from '@/src/screeb';
import synchronizeSSOProfileAction from '@/src/actions/synchronizeSSOProfile';
import { LoginError, LoginFormValues } from '@/src/types/api';

import { useApplicationType } from '@/src/hooks';

import useBranding from '@/src/hooks/branding/useBranding';
import useLinkAccountInfo from '@/src/hooks/useLinkAccountInfo';
import useSSOUserData from '@/src/hooks/useSSOUserData';
import useUserSessionType from '@/src/hooks/useUserSessionType';
import { analyticsEvent } from '@/src/analytic_tag_manager';
import mfaAuthAction from '../actions/mfaAuthAction';
import { useEmailVerificationDialogState } from '@/components/templates/EmailVerification/EmailVerificationDialog';

type MfaValues = {
  mfaToken: string;
  oneTimePIN: string;
  shouldRememberDevice: boolean;
};

export const MFA_CANAL_VALUES = {
  SMS: 'SMS' as const,
  EMAIL: 'EMAIL' as const,
};

export type MAF_CANAL_TYPES = keyof typeof MFA_CANAL_VALUES;

const deviceRecognitionActions = actionsGenerator({
  resource: 'deviceRecognition',
  chunkUrlResource: 'device/recognition',
});

const checkMatchSSOProfileAction = actionsGeneratorV2({
  resource: 'ssoCheckMatchSSO',
  chunkUrlResource: 'sso/check-match-sso',
});

const useAuthentication = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { data: linkAccountInfo, linkAccount } = useLinkAccountInfo();
  const applicationType = useApplicationType();
  const { data: branding } = useBranding();
  const {
    data: ssoData,
    clean: cleanSSOData,
    setData: setSSOData,
  } = useSSOUserData();
  const { main: userSessionType } = useUserSessionType();

  const {
    enable: enableEmailVerificationDialog,
  } = useEmailVerificationDialogState();

  const userRefreshAction = async (state: any) => {
    const currentAuthenticationItem = get(state, 'authentication.item');
    const user = await asyncActions(dispatch, usersActions.getOne('me'));

    return authActions.setItem({
      ...currentAuthenticationItem,
      ...user,
    });
  };

  const userRedirectAfterLogin = React.useCallback(
    state => {
      let next = router?.query?.next as string;

      // Specific part where the user comes from a cardHCD page
      if (next?.startsWith(APP_ROUTES.ACCOUNT.ADD_INSURANCE)) {
        const nextNext = new URL(
          router.query.next as string,
          window.location.href,
        ).searchParams.get('next');

        const user: User | undefined = state?.authentication?.item;
        const hasAmc = user?.userPatientInformation?.amcs?.length;

        // AMC contract found, skip "next", redirection to "nextNext"
        if (hasAmc) {
          if (nextNext && nextNext?.length > 0) {
            next = nextNext;
          }
        }
      }

      router.push(safeRedirectURL(next) ?? '/');
    },
    [router],
  );

  const mfaAuthenticationStartsAnalytics = () => {
    analyticsEvent({
      category: 'Authentification',
      action: 'StartAuthentification2Facteurs',
    });
  };

  const mfaAuthenticationSuccessAnalytics = () =>
    analyticsEvent({
      category: 'Authentification',
      action: 'SuccessAuthentification2Facteurs',
    });

  const mfaAuthenticationErrorAnalytics = (err: any) => {
    const label = (err?.code as string) || '';
    analyticsEvent({
      category: 'Authentification',
      action: 'FailAuthentification2Facteurs',
      label,
    });
  };

  const loginSuccessAnalytics = () =>
    analyticsEvent({
      action: 'loginSuccess',
      category: 'authentication',
      label: userSessionType,
    });

  const loginErrorAnalytics = () =>
    analyticsEvent({
      action: 'loginFail',
      category: 'authentication',
    });

  const handleDeviceRecognition = async (userId: string) => {
    const storedDeviceRecognitionToken = localStorage.getItem(
      'deviceRecognitionToken',
    );

    try {
      const verifyDevice = await asyncActions(
        dispatch,
        deviceRecognitionActions.create({
          userId,
          deviceRecognitionToken: storedDeviceRecognitionToken,
          applicationType,
          userType: 'PATIENT',
          timeZone: dayjs.tz.guess(),
        }),
      );

      localStorage.setItem(
        'deviceRecognitionToken',
        verifyDevice.deviceRecognitionToken,
      );
    } catch (e) {
      // Silent
    }
  };

  const checkEmailValidation = (isEmailConfirmed: boolean) => {
    if (isEmailConfirmed) return;
    enableEmailVerificationDialog();
  };

  const commonActions: any[] = [
    async state => {
      const currentUserPharmacyId =
        state?.authentication?.item?.userPatientInformation
          ?.teleconsultationRelayId;
      if (
        !branding?.id ||
        currentUserPharmacyId === branding?.id ||
        isMutual(branding) // don't link if it's a mutual
      )
        return serialActions.continue();
      await asyncActions(
        dispatch,
        pharmacyUsersActions.create({
          linkAccountToken: branding?.subdomain,
        }),
      );
    },
    userRefreshAction,
    async () => {
      screebEventTrack('Login');
    },
    async () => {
      if (!linkAccountInfo) return serialActions.continue();
      if (linkAccountInfo.tokenVerified) {
        linkAccount();
        // Add a 1 second delay before redirecting to the appointment
        // page (user must be logged in, and own the appointment)
        // redirecting before the backend is already linked the appointment
        // will produce an error
        await sleep(1000);
      }
    },
    async state => {
      if (!ssoData?.token) return serialActions.continue();
      const hasProfile =
        (
          (state?.authentication?.item as PatientUser | undefined)
            ?.userPatientInformation?.userPatientProfiles ?? []
        ).length > 0;
      if (!hasProfile) {
        router.push(APP_ROUTES.SSO.CONFIRM);
      }
      const affiliates = ssoData.ssoProfile?.affiliates || [];
      const hasAffiliates = affiliates && affiliates.length > 0;
      if (hasAffiliates) {
        const remainAffs = await asyncActions(
          dispatch,
          synchronizeSSOProfileAction.getOne(ssoData.token),
        );
        if (remainAffs.length > 0) {
          setSSOData(ssoData.token, {
            ...ssoData.ssoProfile,
            affiliates: remainAffs,
          });
          router.push(APP_ROUTES.ACCOUNT.SSO_PROFILE_PATIENT);
          return serialActions.break();
        }
        cleanSSOData();
        router.push(ssoData.redirectTarget ?? SSO_DEFAULT_REDIRECT_TARGET);
        return serialActions.break();
      }
      const matched = await asyncActions(
        dispatch,
        checkMatchSSOProfileAction.getOne(ssoData.token),
      ).catch(() => false);
      if (!matched) {
        router.push(APP_ROUTES.ACCOUNT.SSO_PROFILE_PATIENT);
        return serialActions.break();
      }
      const nextAction = await asyncActions(
        dispatch,
        linkSSOAction.create({
          ssoToken: ssoData.token,
        }),
      )
        .then(() => {
          cleanSSOData();
          router.push(ssoData.redirectTarget ?? SSO_DEFAULT_REDIRECT_TARGET);
          return serialActions.break();
        })
        .catch(() => serialActions.continue());
      return nextAction;
    },
    async state =>
      checkEmailValidation(!!state?.authentication?.item?.isEmailConfirmed),
    userRedirectAfterLogin,
  ];

  const loginWithCredentials = async (formValues: LoginFormValues) => {
    return asyncActions(
      dispatch,
      serialActions.serial([
        () => {
          const action = authActions.create(
            {
              applicationType,
              type: 'PATIENT',
              username: formValues.email,
              password: formValues.password,
              ssoToken: ssoData?.token,
            },
            false,
          );

          action.onSuccess = response => {
            loginSuccessAnalytics();
            handleDeviceRecognition(response.id);
          };
          action.onError = (actionError: LoginError) => {
            if (actionError.status === 461) {
              mfaAuthenticationStartsAnalytics();
              // Multi authentication factor needed
              router.push({
                pathname: APP_ROUTES.LOGIN_CONFIRMATION,
                query: {
                  ...router.query,
                  mfaToken: actionError.data?.mfaToken,
                },
              });
              return serialActions.break();
            }
            loginErrorAnalytics();
            return serialActions.break();
          };
          return action;
        },
        ...commonActions,
      ]),
    );
  };

  const loginWithMfaToken = async (mfaValues: MfaValues) => {
    return asyncActions(
      dispatch,
      serialActions.serial([
        () => {
          const action = mfaAuthAction.create({
            applicationType,
            type: 'PATIENT',
            ...mfaValues,
          });

          action.onSuccess = () => {
            mfaAuthenticationSuccessAnalytics();
          };
          action.onError = err => {
            mfaAuthenticationErrorAnalytics(err);
            return serialActions.break();
          };
          return action;
        },
        ...commonActions,
      ]),
    );
  };

  const resendCode = (mfaToken: string, canalType?: MAF_CANAL_TYPES) => {
    return asyncActions<MfaDTO>(
      dispatch,
      mfaAuthAction.create(
        {},
        {
          chunkUrlResource: `mfa/${mfaToken}/resend`,
          params: {
            canalType,
          },
        },
      ),
    );
  };

  return {
    loginWithCredentials,
    loginWithMfaToken,
    resendCode,
  };
};

export default useAuthentication;
