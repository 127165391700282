import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { asyncActions } from '@docavenue/core';
import { SESSIONSTORAGE_KEYS } from '@/src/constants';
import { linkAccountsActions } from '../actions';
import { LinkAccountInfoType } from '../types/LinkAccountInfo';

const clean = () => {
  window.sessionStorage.removeItem(SESSIONSTORAGE_KEYS.LINK_ACCOUNT);
};

const setLinkAccountInfo = (
  token: string,
  data: Omit<LinkAccountInfoType, 'token'>,
) => {
  if (typeof window === 'undefined') {
    throw Error('Dont call setLinkAccountInfo in server side');
  }
  const currentInfo = JSON.parse(
    window.sessionStorage.getItem(SESSIONSTORAGE_KEYS.LINK_ACCOUNT) ?? '{}',
  ) as LinkAccountInfoType;
  const storedData: LinkAccountInfoType =
    token === currentInfo.token
      ? {
          ...currentInfo,
          ...data,
        }
      : {
          ...data,
          token,
        };
  sessionStorage.setItem(
    SESSIONSTORAGE_KEYS.LINK_ACCOUNT,
    JSON.stringify(storedData),
  );
};

const getLinkAccountInfo = () => {
  if (typeof window === 'undefined') return;
  const item = window.sessionStorage.getItem(SESSIONSTORAGE_KEYS.LINK_ACCOUNT);
  if (!item) return;
  try {
    return JSON.parse(item) as LinkAccountInfoType;
  } catch {}
};

const linkAccount = (dispatch: Dispatch) => {
  const linkAccountInfo = getLinkAccountInfo();
  if (!linkAccountInfo) return;

  const { searchParams } = new URL(document.location.href);
  const { token: linkToken, documentId: linkDocumentId } = linkAccountInfo;
  const linkTokenVerified =
    linkAccountInfo.tokenVerified || searchParams.get('linkTokenVerified');
  if (linkToken && linkTokenVerified) {
    asyncActions(
      dispatch,
      linkAccountsActions.create({
        linkAccountToken: linkToken,
        documentId: linkDocumentId,
        verifiedBirthdateToken: linkTokenVerified,
      }),
    ).finally(clean);
  }
};

export default function useLinkAccountInfo() {
  const dispatch = useDispatch();
  const data = getLinkAccountInfo();
  return {
    data,
    clean,
    setData: setLinkAccountInfo,
    linkAccount: () => linkAccount(dispatch),
  };
}
