import React from 'react';
import { useSelector } from 'react-redux';
import useLinkAccountInfo from '@/src/hooks/useLinkAccountInfo';
import useSSOUserData from '@/src/hooks/useSSOUserData';

export type SessionType = 'SSO' | 'LinkAccount' | 'Appointment' | undefined;

/**
 * We have a lot of session types (SSO, LinkAccount...etc)
 * At some point we need to know the current session type
 * TODO: define if we could have multiple session types
 * at the same time and how we should handle these cases
 */
export default function useUserSessionType() {
  const temporaryAppointment = useSelector(
    (state: any) => state?.temporaryAppointments?.item,
  );
  const { data: linkAccountInfo } = useLinkAccountInfo();
  const { data: ssoData } = useSSOUserData();

  const activeSessionTypes = React.useMemo(() => {
    const list: SessionType[] = [];
    if (ssoData) {
      list.push('SSO');
    }
    if (linkAccountInfo) {
      list.push('LinkAccount');
    }
    if (temporaryAppointment?.cardHcd) {
      list.push('Appointment');
    }

    const main = list[0];
    return { main, list };
  }, [ssoData, linkAccountInfo, temporaryAppointment]);

  return activeSessionTypes;
}
